"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setAtPath = exports.swapInSiteVariables = exports.buildXmlStructure = exports.familyTree = exports.getDisplayName = exports.computeId = exports.computePath = exports.isModuleVariables = exports.isOrientation = exports.defaultOrientation = exports.isIdenticalFlatArray = void 0;
const coreTypes_1 = require("./coreTypes");
const json_1 = require("./json");
/**
 * Compares the elements of two flat arrays efficiently and reliably.
 * @param a1 operand array 1
 * @param a2 operand array 2
 * @returns boolean indicating whether the two arrays are equal
 */
function isIdenticalFlatArray(a1, a2) {
    // Tim Down: https://jsben.ch/1Mq8s
    let i = a1.length;
    if (i !== a2.length)
        return false;
    while (i--) {
        if (a1[i] !== a2[i])
            return false;
    }
    return true;
}
exports.isIdenticalFlatArray = isIdenticalFlatArray;
const orientationPattern = /^(vertical|horizontal)$/;
exports.defaultOrientation = 'vertical';
const isOrientation = (o) => {
    return typeof o === 'string' ? orientationPattern.test(o) : false;
};
exports.isOrientation = isOrientation;
const isModuleVariables = (v) => {
    return ((0, json_1.isJSONObject)(v) &&
        '_version' in v &&
        typeof v._version === 'number' &&
        '_instance' in v &&
        typeof v._instance !== 'undefined' &&
        (0, json_1.isJSONObject)(v._instance));
};
exports.isModuleVariables = isModuleVariables;
/**
 * Use `reduceRight` as an advanced `filter` of `lineageAll` to list only the module ids
 * required to describe a module's position. `lineageAll` includes every component layer
 * of the current page, but if used as a `path` it would severely complicate editing
 * groups since every child or step-child would need its `path` changed.)
 * @param lineageAll describes the entire module id path up to here.
 * @param all contains all the modules, cores, and components in the module graph from which path will be computed.
 * @param parentId the core this module is trying to connect to.
 * @param parentMid the module id of the module with `parentId` since we are evaluating a specific
 *                  module, not a core (as cores may be attached to many modules)
 * @returns the subset of `lineageAll` that describes the path for this module.
 */
function computePath(lineageAll, all, parentId, parentMid, groupContext) {
    const { modules, cores } = all;
    let nextGroupId;
    if (!parentId) {
        return [];
    }
    // Since the path is being derived for a new/candidate child, include this level.
    const lineage = typeof parentMid === 'string' ? [...lineageAll, parentMid] : lineageAll;
    const returnedPath = lineage.reduceRight(({ isDone, path }, mid, i, arr) => {
        if (isDone) {
            return { isDone, path };
        }
        const module = modules[mid];
        if (typeof module === 'undefined') {
            throw new Error(`module(${mid}) does not exist`);
        }
        const core = cores[module.coreId];
        if (typeof core === 'undefined') {
            throw new Error(`core(${module.coreId}) does not exist`);
        }
        const isInGroup = !!module.groupId;
        if (
        // This parent IS the group context, so no path needed.
        core.id === groupContext ||
            // This parent is in the same context as the group context and is not a
            // group itself, so no path needed. (if the parent is a group, we will need
            // to identify its moduleId in the path, so this is not a valid case for this conditional)
            (isInGroup &&
                module.groupId === groupContext &&
                core.coreType === coreTypes_1.coreTypes.default)) {
            return { isDone: true, path };
        }
        if (
        // Positionally situated directly in slot left open by a group?
        // ie, first-encountered element in reduceRight is part of a group.
        (arr.length === i + 1 &&
            (core.coreType === coreTypes_1.coreTypes.group || isInGroup)) ||
            // This is the GID you are looking for.
            nextGroupId === core.id) {
            path.unshift(mid);
            nextGroupId = module.groupId;
        }
        // This level is attached to the page, so don't go on to further-left MIDs.
        if (module.pageId) {
            return { isDone: true, path };
        }
        else {
            return { isDone: false, path };
        }
    }, { isDone: false, path: [] });
    return returnedPath.path;
}
exports.computePath = computePath;
const getSixOctets = (uuid) => uuid.replace('-', '').slice(0, 12);
/**
 * Computes an HTML safe identifier from the given `ids`. The computed identifier is not used
 * within the system but exists as a "hook" for custom styles and behavior.
 * @param ids from which HTML id will be computed
 * @returns a sufficiently unique subset of the values in `RelevantIdentifiers` prefixed such that
 * it will always be a valid HTML id.
 */
const computeId = (ids) => {
    if (ids.groupModuleId && ids.groupModuleId !== ids.mid) {
        return `i${getSixOctets(ids.groupModuleId)}_${getSixOctets(ids.mid)}`;
    }
    return `i${getSixOctets(ids.mid)}`;
};
exports.computeId = computeId;
/**
 * Builds a user facing module name for use in the admin UI and the XML strcuture.
 */
function getDisplayName(moduleName, coreName, coreType, componentName) {
    // The default name for modules used to be `${componentName} Module`, but now
    // it's just the component name.
    if (componentName && moduleName === `${componentName} Module`) {
        return componentName;
    }
    // And empty moduleName should default to the coreName.
    return moduleName || coreName;
}
exports.getDisplayName = getDisplayName;
/**
 * This is to build up a simple tree that shows just module relationships. It allows
 * us to then build up the guest view or the admin view. It's also used to build a
 * selectable XML model for `about`.
 * @param modules the array of modules at this level to convert into nodes
 * @param all AllPageParts
 * @param lineage array of moduleIds up the tree
 * @returns FamilyTreeNode[], each can have kids
 */
function familyTree(modules, all, parentId = null, lineage = []) {
    return modules.map((module) => {
        const core = all.cores[module.coreId];
        if (typeof core === 'undefined') {
            throw new Error(`core(${module.coreId}) not in page data`);
        }
        const component = all.components[core.componentId];
        if (typeof component === 'undefined') {
            throw new Error(`component(${core.componentId}) not in page data`);
        }
        const node = {
            kind: component.reactName,
            id: module.id,
            cid: core.id,
            htmlId: (0, exports.computeId)({ mid: module.id, groupModuleId: module.groupId }),
            name: getDisplayName(module.name, core.name, core.coreType, component.reactName),
            coreName: core.name,
            path: module.path || undefined,
            parentId,
            lineage: [],
            lineageAll: [...lineage],
            coreType: core.coreType,
            i: (module.parentSlotIndex || module.pageIndex || 10) / 10 - 1,
        };
        node.lineage = (node.path && lineage) ?? [];
        const children = Object.values(all.modules).filter((candidate) => {
            // Only consider parentId===coreId.
            if (candidate.parentId !== module.coreId)
                return false;
            // Without a path, but parentId===coreId, the parent is a page-level module, so it's a child.
            if (candidate.path.length === 0)
                return true;
            return isIdenticalFlatArray(candidate.path, computePath(lineage, all, core.id, module.id, candidate.groupId));
        });
        if (children.length) {
            // need to sort here since children are assigned in random order.
            children.sort((a, b) => (a.parentSlotIndex ?? 0) < (b.parentSlotIndex ?? 0) ? -1 : 1);
            node.kids = familyTree(children, all, node.cid, [
                ...lineage,
                node.id,
            ]);
        }
        return node;
    });
}
exports.familyTree = familyTree;
const setAttributeIfRelevant = (node, attr, value) => {
    if (value) {
        node.setAttribute(attr, value);
    }
};
const makeXmlNode = (document, parent, treeNodes, all, page) => {
    treeNodes.forEach((treeNode) => {
        const xmlNode = document.createElement(treeNode.kind);
        /* Append this xmlNode. We still have a reference to it */
        parent.appendChild(xmlNode);
        xmlNode.id = treeNode.htmlId ?? '';
        xmlNode.setAttribute('coreId', treeNode.cid);
        xmlNode.setAttribute('moduleId', treeNode.id);
        xmlNode.setAttribute('coreType', treeNode.coreType);
        xmlNode.setAttribute('name', treeNode.name);
        xmlNode.setAttribute('i', treeNode.i.toString());
        const setAttribute = setAttributeIfRelevant.bind(null, xmlNode);
        setAttribute('slot', treeNode.slot);
        setAttribute('groupModuleId', treeNode.groupModuleId);
        setAttribute('groupCoreId', treeNode.groupCoreId);
        setAttribute('path', treeNode.path?.join(','));
        if (treeNode.coreType !== 'default') {
            setAttribute('groupName', treeNode.coreName);
        }
        const component = Object.values(all.components).find((x) => x.reactName === treeNode.kind);
        const element = page.pageModuleLookup[xmlNode.id];
        if (!component || !element) {
            return;
        }
        const slots = new Set();
        // Add all the defined slots, whether or not populated with children.
        if (component && component.slotConfiguration) {
            Object.keys(component.slotConfiguration).forEach((x) => slots.add(x));
        }
        if (slots.size > 0) {
            const layout = (0, json_1.isJSONValue)(element.props) && (0, json_1.isJSONObject)(element.props)
                ? element.props.layout ?? null
                : null;
            let orientation = 'vertical';
            if ((0, json_1.isJSONObject)(layout) && (0, exports.isOrientation)(layout.orientation)) {
                orientation = layout.orientation;
            }
            xmlNode.setAttribute('orientation', orientation);
        }
        if ('kids' in treeNode) {
            treeNode.kids.forEach((kid) => {
                if (kid.slot) {
                    slots.add(kid.slot);
                }
            });
            makeXmlNode(document, xmlNode, treeNode.kids, all, page);
        }
        if (slots.size > 0) {
            xmlNode.setAttribute('slots', Array.from(slots).sort().join(','));
        }
    });
};
/**
 * Builds a model of the module tree that is queryable by CSS selectors.
 * Returns `undefined` if `document` is undefined.
 */
function buildXmlStructure({ page, tree, showId, domainName, all, }) {
    if (typeof document === 'undefined') {
        return undefined;
    }
    const doc = document.implementation.createDocument(null, 'Root', null);
    // console.log('✨✨', doc, tree, page);
    const root = doc.children.item(0);
    if (root !== null) {
        root.setAttribute('id', 'root');
        root.setAttribute('pathname', page.pathname);
        if (showId) {
            root.setAttribute('showId', showId);
        }
        if (domainName) {
            root.setAttribute('domainName', domainName);
        }
        root.setAttribute('pageId', page.id);
        makeXmlNode(doc, root, tree, all, page);
    }
    return doc;
}
exports.buildXmlStructure = buildXmlStructure;
/**
 * Function to swap in actual values for a module's props,
 * based on a lookup table of site variables and an array of
 * site variable references.
 * @param coreVariablesData Array of site variable references.
 * @param siteVariableLookup Record of site variable names and their values.
 * @param props The module props.
 */
function swapInSiteVariables(coreVariablesData, siteVariableLookup, props) {
    const vars = (coreVariablesData ?? []).map((v) => {
        let value = siteVariableLookup ? siteVariableLookup[v.ref] : undefined;
        // handle asset values
        if (value && (0, json_1.isJSONObject)(value) && 'uri' in value) {
            value = value.uri;
        }
        return {
            ...v,
            value,
        };
    });
    vars.forEach((v) => {
        setAtPath(props, v.path, v.value);
    });
}
exports.swapInSiteVariables = swapInSiteVariables;
/**
 * A simple function to set a value in an object at a path. Falsey paths
 * and falsey input objects will return undefined. Comparable to lodash's
 * `set`.
 * @param object the object to get the value from
 * @param path the path to the value, in form of `a.b.c` for `{a: {b: {c: 1}}}`
 */
function setAtPath(object, path, setValue) {
    if (!object || !path || typeof path !== 'string') {
        return undefined;
    }
    const steps = path.split('.');
    let nextStep = steps.shift();
    let value = object;
    while (nextStep &&
        value &&
        ((0, json_1.isJSONObject)(value) || Array.isArray(value)) &&
        nextStep in value) {
        if (steps.length === 0) {
            if (Array.isArray(value) && !isNaN(parseInt(nextStep))) {
                value[parseInt(nextStep)] = setValue;
            }
            else if ((0, json_1.isJSONObject)(value)) {
                value[nextStep] = setValue;
            }
            return;
        }
        if (Array.isArray(value) && !isNaN(parseInt(nextStep))) {
            value = value[parseInt(nextStep)];
        }
        else if ((0, json_1.isJSONObject)(value)) {
            value = value[nextStep];
        }
        nextStep = steps.shift();
    }
}
exports.setAtPath = setAtPath;
