import { useEffect, useState, useRef } from 'react';
import { ModuleRenderInstructions, useShowInstructions, } from '@backstage-components/base';
import { useSubscription } from 'observable-hooks';
export function useModuleVisibility(definition) {
    const isMountingRef = useRef(false);
    const initialVisibility = 'moduleRender' in definition.props &&
        typeof definition.props.moduleRender === 'boolean'
        ? definition.props.moduleRender
        : true;
    const [shouldRenderModule, setShouldRenderModule] = useState(initialVisibility);
    const { broadcast, observable } = useShowInstructions(ModuleRenderInstructions, definition);
    useSubscription(observable, (instr) => {
        switch (instr.type) {
            case 'ModuleRender:change-module-visibility': {
                // Make case insenitive
                const type = instr.meta.type.toLowerCase();
                if (type === 'hide') {
                    setShouldRenderModule(false);
                }
                else if (type === 'show') {
                    setShouldRenderModule(true);
                }
                else if (type === 'toggle') {
                    setShouldRenderModule(!shouldRenderModule);
                }
            }
        }
    });
    useEffect(() => {
        isMountingRef.current = true;
    }, []);
    useEffect(() => {
        // skip broadcasts on initial renders
        if (!isMountingRef.current) {
            broadcast({
                type: 'ModuleRender:module-visibility-changed',
                meta: {
                    moduleRender: shouldRenderModule,
                },
            });
        }
        else {
            isMountingRef.current = false;
        }
    }, [broadcast, definition.component, shouldRenderModule]);
    return shouldRenderModule;
}
